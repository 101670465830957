body {
  background-color: #fff;
}

.app-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: flex-start;
}

.container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.app {
  background-color: #fff;
  text-align: center;
  width: 100%;
  padding: 0 20px;
  max-width: 1300px;
}

@media screen and (min-width: 1800px) {
  .app {
    background-color: #fff;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    max-width: 1800px;
  }
}

.header {
  margin-bottom: 10px;
}

.app-title {
  color: #3772ff;
}

.alt-logo {
  /* color: #0056b3; */
  font-family: monospace;
  font-weight: bold;
  font-size: 16px;
}

.search-bar {
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 5px;
  font-size: 16px;
}

.add-button {
  text-align: center;
  margin-bottom: 10px;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.add-button button {
  display: inline-block;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  background-color: #0056b3;
  color: #fff;
  transition: background-color 0.3s, transform 0.3s;
}

.add-button button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.add-button button:focus {
  outline: none;
}

.add-button button:hover,
.add-button button:focus {
  cursor: pointer;
}

.add-button .button-icon {
  margin-right: 10px;
  vertical-align: middle;
}

.button-icon {
  margin-right: 5px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-add-dialog {
  background-color: #fff;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
}

.modal-dialog {
  background-color: #fff;
  width: 100%;
  max-width: 550px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fafaed;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
}

.modal-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.modal-body .form-group {
  margin-bottom: 20px;
}

.modal-body textarea {
  flex: 1;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.letters-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.letter-card {
  width: 200px; /* Adjust the width as per your preference */
  height: 150px; /* Adjust the height as per your preference */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.featured-card {
  width: 200px;
  height: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.card {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 5px;
  margin: 5px;
  background-color: #fefbf0;
}

.card-text {
  font-size: 16px;
  font-family: monospace;
}

.card-preview {
  font-family: monospace;
  font-size: 14px;
}

.btn-primary {
  background-color: #3772ff;
  color: #fff;
  border: none;
}

.btn-primary:hover {
  background-color: #2352e6;
}

.btn-danger {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
}

.btn-danger:hover {
  background-color: #e63f3f;
}

.modal-body {
  padding: 20px;
}

.modal-body .form-group {
  margin-bottom: 20px;
}

.modal-body label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.modal-body input,
.modal-body textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.modal-footer {
  padding: 10px 20px;
  border-top: 1px solid #ccc;
  text-align: right;
}

.form-group {
  align-items: flex-start;
  flex-direction: column;
  margin-right: 15px;
}

.form-group label {
  width: 0px;
  margin-right: 10px;
}

.label-top-left {
  display: block;
  float: left;
}

.big-textarea {
  height: 150px;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-size: 30px;
  color: #000;
}

.character-count {
  position: bottom;
  font-size: 12px;
  color: #777;
}

.disabled-button {
  cursor: not-allowed;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 16px;
  border: none;
  font-size: 13px;
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-icon {
  margin-left: 8px;
}

.timestamp-text {
  font-family: "Courier New", Courier, monospace; /* Change 'Courier New' to your desired font */
  font-size: 13px;
  color: #333; /* Adjust color as needed */
}

.footer {
  position: fixed;
  bottom: 0;
  left: 50%; /* Center the footer horizontally */
  transform: translateX(
    -50%
  ); /* Adjust the position based on half of the footer's width */
  width: 100%;
  background-color: rgba(55, 114, 255, 0.8);
  color: #fff;
  text-align: center;
  padding: 10px 20px;
}

/* .footer { */
/*   position: fixed; */
/*   bottom: 0; */
/*   width: 100%; */
/*   background-color: rgba( */
/*     55, */
/*     114, */
/*     255, */
/*     0.8 */
/*   ); /1* Adjust the alpha value (0.8) for medium transparency *1/ */
/*   color: #fff; /1* Adjust text color as needed *1/ */
/*   text-align: center; */
/*   /1* padding: 10px 20px; /2* Adjust padding as needed *2/ *1/ */
/* } */

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer p,
.footer-nav {
  margin: 0px 0;
  font-size: 13px;
}

.footer-nav a {
  margin: 0 5px;
}

.fab {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: fixed;
  bottom: 60px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  font-size: 24px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.fab:hover {
  background-color: #0056b3;
}

/** View Letter **/
.letter-info {
  white-space: pre-wrap;
  text-align: left;
  font-family: monospace;
  font-size: 14px;
  margin-left: 10px;
}

.letter-text {
  font-family: monospace;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  margin-left: 10px;
}

@media only screen and (max-width: 768px) {
  .letters-container {
    flex-wrap: wrap;
    max-width: 100%;
  }

  .letter-card {
    width: 45%; /* 33.33% width for each item with some margin */
    margin: 1px;
    /* margin-left: 20px; */
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 50%;
  }

  .featured-card {
    width: 45%; /* 33.33% width for each item with some margin */
    margin: 1px;
    /* margin-left: 20px; */
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 50%;
  }

  .card {
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px;
    margin: 5px;
    background-color: #fefbf0;
  }

  .card-text {
    font-size: 13px;
    font-family: monospace;
  }

  .card-preview {
    font-family: monospace;
    font-size: 13px;
  }

  .letter-info {
    white-space: pre-wrap;
    text-align: left;
    font-family: monospace;
    font-size: 13px;
    margin-left: 0px;
  }

  .letter-text {
    font-family: monospace;
    font-size: 13px;
    text-align: left;
    margin-left: 0px;
    margin-right: -15px;
    /* hyphens: auto; /1* Enable automatic hyphenation *1/ */
  }

  .logo {
    height: 50px;
    max-width: 80%;
    margin: 0 auto; /* Center the logo horizontally */
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 11px;
  }

  .footer p,
  .footer-nav {
    margin: 3px 0;
    font-size: 9px;
  }

  .app-container {
    max-width: 90%; /*  */
    margin: 0 auto;
    /* overflow-x: hidden; /1* I SPENT 24 Hours in this SHIT *1/ */
  }

  .search-bar {
    /* margin: 0 auto; /1* Center the search bar horizontally *1/ */
    width: 95%;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .search-input {
    width: 95%;
    margin: 0 auto;
    font-size: 16px;
  }

  /* .form-group label { */
  /*   display: flex; /1* Ensure labels appear on their own line *1/ */
  /*   font-weight: bold; /1* Optionally, make labels bold for emphasis *1/ */
  /*   width: 65px; */
  /* } */

  /* .form-group input { */
  /*   width: 1px; */
  /* } */

  /* .form-group textarea { */
  /*   box-sizing: border-box; /1* Include padding and border in the width calculation *1/ */
  /* } */

  .fab {
    position: fixed;
    bottom: 60px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff;
    color: #fff;
    font-size: 14px;
    border: none;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .fab:hover {
    background-color: #0056b3;
  }

  .add-button button {
    display: inline-block;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    background-color: #0056b3;
    color: #fff;
    transition: background-color 0.3s, transform 0.3s;
  }

  .timestamp-text {
    font-family: "Courier New", Courier, monospace; /* Change 'Courier New' to your desired font */
    font-size: 11px;
    color: #333; /* Adjust color as needed */
  }

  .big-textarea {
    height: 130px;
    width: 100%;
  }

  .big-textarea::placeholder {
    font-size: 15px;
  }

  .form-group {
    align-items: flex-start;
    flex-direction: column;
  }

  .form-group label {
    width: 0px;
    margin-right: 10px;
  }

  .form-group input {
  }

  .label-top-left {
    display: block;
    float: left;
  }

  .full-width {
    width: calc(100% - 20px); /* Adjust the padding */
  }

  .modal {
  }

  .modal-add-dialog {
  }

  .modal-body {
  }

  .modal-body .form-group {
    margin-bottom: 20px;
  }

  .reads-text {
    font-family: "Courier New", Courier, monospace;
    right: 10px;
    text-align: right;
    font-size: 11px;
  }
}

.load-letters {
  margin-left: 0px;
}

a[data-scroll] {
  color: white; /* Set the text color to white */
}

.preview-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 16px;
  border: none;
  background-color: #fefbf0;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.preview-button:hover {
  background-color: #0056b3;
  color: #fff;
}

.rwd-video {
  background: #99ccff;
}
.rwd-video {
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}
.rwd-video iframe,
.rwd-video object,
.rwd-video embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.reads-text {
  font-family: "Courier New", Courier, monospace;
  right: 10px;
  text-align: right;
  font-size: 11px;
}

.pulsating-icon {
  animation: pulsate 1s ease-in-out infinite alternate;
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Default Light Mode */
:root {
  --bg-color: #ffffff;
  --text-color: #000000;
  --card-bg: #f5f5f5;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.fade-icon {
  animation: fade 1s infinite;
}

.las.la-eye {
  height: 16px;
  width: 16px;
}
