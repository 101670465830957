.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-width: 768px) {
  /* Target screens less than 768px wide */
  .login-box {
    margin-bottom: 90px; /* Add margin-top for mobile screens */
  }
}

.login-box {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-box h2 {
  margin-bottom: 20px;
}

.form-group-login {
  margin-bottom: 20px;
}

.login-label {
  display: block;
  margin-bottom: 5px;
}

.login-input {
  width: 100%; /* Maintains full width within the container */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box; /* Key addition */
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0087c1;
}
