.approve-button.enabled {
  background-color: #0056b3;
}

.approve-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.letter-review-box {
  background-color: #f5f5f5;
  padding: #1rem;
  margin: 0 auto;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .letter-review-box {
    background-color: #f5f5f5;
    padding: #1rem;
    text-overflow: ellipsis;
    margin: 0 auto;
    overflow: hidden;
  }

  .letter-item {
    width: 80%;
    margin: 0 auto;
    text-overflow: ellipsis;
  }
}
