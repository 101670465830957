.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.team-member {
  margin: 10px;
  text-align: center;
  box-sizing: border-box;
}

.team-member-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
}

.team-member-role {
  font-size: smaller;
}

.team-member-name {
  font-size: 10px;
}

.technologies-container {
  display: flex;
  justify-content: center;
}

.technology {
  margin: 10px;
  text-align: center;
  flex: 1 1 45%; 
  box-sizing: border-box;
}

.blck_logo{
  width: 200px;
  height: 35px;
}

@media (max-width: 600px) {
  .team-member {
    flex: 1 1 calc(30% - 10px); /* Make elements smaller for mobile view */
    margin: 5px; 
  }

  .team-member-image {
    width: 85px; /* Smaller image for mobile */
    height: 85px; /* Smaller image for mobile */
  }

  .team-member-name {
    font-size: 8px; /* Smaller font for mobile */
  }

  .technology {
    flex: 1 1 45%; 
    margin: 5px; 
  }

  .blck_logo {
    width: 200px;
    height: 35px;
  }
}
